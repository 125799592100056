import React from "react"
import { css } from "@emotion/core"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { motion } from "framer-motion"

import Head from "../components/Head"
import Template from "../components/Template"

export default () => {
  const query = useStaticQuery(graphql`
    query {
      images: allFile {
        edges {
          node {
            relativePath
            childImageSharp {
              fluid(maxWidth: 500, quality: 70) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  `)

  const photos = query.images.edges.map(item => {
    item.node.fullPath = "/assets/" + item.node.relativePath
    return item.node
  })

  const data = require("../cms/gallery.json")

  let photoNumber = 0


  /* Animate in gallery images */

  const container = {
    show: {
      transition: {
        staggerChildren: .3
      }
    }
  }

  const motionItem = {
    hidden: { y: 10, opacity: 0 },
    show: { y: 0, opacity: 1 }
  }

  return (
    <>
      <Head title="Gallery" />

      <Template
        h1="Gallery"
      >
        <motion.div
          variants={container}
          initial="hidden"
          animate="show"
          css={css`
            display: grid;
            grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
            grid-row-gap: 3rem;
            grid-column-gap: 4rem;

            @media (max-width: 500px) {
              grid-template-columns: 1fr;
            }

            > * {
              grid-row: span 2;
            }

            .filler {
              grid-row: span 1;
              height: 100px;
            }

            @media (max-width: 864px) {
              grid-row-gap: 2rem;

              .filler {
                display: none;
              }
            }
          `}
        >
          {data.photos.map(item => {
            const fullPath = item.photo
            const src = photos.find(photo => photo.fullPath === fullPath)

            photoNumber++

            return (
              <React.Fragment key={item.photo}>
                {photoNumber === 2 && <div className="filler" />}

                <motion.div
                  variants={motionItem}
                >
                  <a
                    href={src.fullPath}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Img
                      fluid={src.childImageSharp.fluid}
                      css={css`
                        border-radius: 4px;
                      `}
                    />
                  </a>

                  <p
                    css={css`
                      margin-top: .7rem;
                      color: var(--text-gray);
                      font-style: italic;
                    `}
                  >
                    {item.caption || ""}
                  </p>
                </motion.div>
              </React.Fragment>
            )
          })}
        </motion.div>
      </Template>
    </>
  )
}